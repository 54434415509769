$page-width: 1280px;
$drawer-width: 496px;

// brand color
$primary-color: #1a73ba;
$background-color: #f6f7fb;

// neutral color
$white: #ffffff;
$gray-light-0: #d8d8d8;
$gray: #f3f3f3;
$gray-light: #e8e8e8;
$gray-light-1: #dfdfdf;
$gray-light-1-25: rgba($gray-light-1, 0.25);
$gray-light-2: #9b9b9b;
$gray-medium: #6f6f6f;
$gray-dark-1: #4a4a4a;
$gray-dark-2: #000000;

// function color
$info: #399bff;
$success: #96d368;
$error: #db5157;
$pink-error: #ffd4d6;
$warning: #fad455;
$orange: #f5a623;

// mu color
$china-south: #8ba9c8;
$china-east: #6c93ab;
$china-north: #799c8d;
$europe: #c2a3c0;
$australia: #bbbf90;
$telecom-digital: #b99e73;
$na: #8992b4;
$hk-macao: #a8a1c0;
$sea: #b59090;
$light-orange: #F5A623;

$hover-color: rgba(133, 204, 255, 0.1);

//weight-variable
$weight-300: 300;
$weight-400: 400;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;

//size-variable
$size-2: 2px;
$size-4: 4px;
$size-8: 8px;
$size-10: 10px;
$size-12: 12px;
$size-14: 14px;
$size-16: 16px;
$size-18: 18px;
$size-20: 20px;
$size-24: 24px;
$size-32: 32px;
$size-36: 36px;

$height-1-2: 1.2;
$height-1-25: 1.25;
$height-1-33: 1.25;
$height-1-5: 1.5;
$height-1-6: 1.6;

$box-shadow-1: 0 0 $size-8 0 rgba(0, 0, 0, 0.1);
$box-shadow-2: 0 0 $size-16 0 rgba(0, 0, 0, 0.1);
$box-shadow-3: 0 $size-8 $size-16 0 rgba(0, 0, 0, 0.1);

@mixin inline-block {
  display: inline-block;
  vertical-align: middle;
}

@mixin tooltip-border {
  border-radius: $size-16;
  padding: $size-16;
  box-shadow: $box-shadow-3;
  background-color: rgba(74, 74, 74, 0.9);
}

@mixin icon-size($size) {
  background-size: $size;
  width: $size;
  height: $size;
  background-position: center;
  background-repeat: no-repeat;
  @include inline-block;
}

@mixin unselected {
  user-select: none;
}

@mixin button-style {
  .ant-popover-buttons {
    padding-right: $size-16;

    .ant-btn-sm {
      height: $size-18;
      min-width: 50px;
      font-size: $size-10;
      line-height: $size-12;
      margin-bottom: $size-8;
    }
  }

  .ant-btn-default {
    color: $primary-color;
    border-color: $primary-color;
    box-shadow: none;
  }

  .ant-btn-default:hover:enabled {
    color: $info;
    border-color: $info;
  }

  .ant-btn-primary {
    color: white;
    box-shadow: none;
    border-color: $primary-color;
    background-color: $primary-color;
  }

  .ant-btn-primary:hover:enabled {
    background-color: $info;
    border-color: $info;
  }
}

@mixin input-hover {
  &:hover {
    outline: none !important;
    border-color: $info !important;
    box-shadow: none;
  }

  &:focus {
    outline: none !important;
    border-color: $info !important;
    box-shadow: none;
  }
}

@mixin input-style {
  width: 100%;
  border: 1px solid $gray-light-1;
  height: $size-24;
  border-radius: $size-4;
  font-size: $size-12;
  padding: $size-4 $size-4 2px $size-4;

  @include input-hover
}

@mixin multiply-line-ellipsis($line) {
  display: -webkit-box;
  overflow : hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin none-style-button {
  background: none;
  outline: none;
  border: none;
  box-shadow: none;
}

@mixin error-hint-style {
  position: absolute;
  left: 10px;
  top: 10px;
  background: rgba(219, 81, 87, 0.04);
  border: 1px solid $error;
  border-radius: 4px;
  font-size: 12px;
  color: $gray-dark-1;
  font-weight: 400;
  height: 32px;
  padding: 8px 16px;

  > img {
    margin-right: 4px;
    margin-top: -3px;
  }
}

@mixin warning-hint-style {
  position: absolute;
  left: 4px;
  top: 24px;
  background: rgba(245, 166, 35, 0.04);
  border: 0.5px solid #F5A623;
  border-radius: 4px;
  font-size: 12px;
  color: #4A4A4A;
  font-weight: 400;
  height: 32px;
  padding: 8px 16px;

  > img {
    margin-right: 4px;
    margin-top: -3px;
  }
}

