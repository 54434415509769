@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');
@import "constants";

* {
  box-sizing: border-box;
}

html, body {
  height: 100vh;
  font-family: "Open Sans", Avenir, HelveticaNeue, "Monospaced Number", "Chinese Quote", -apple-system, sans-serif;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.default {
  color: $gray-dark-1;
}

.red {
  color: $error;
}

.yellow {
  color: $orange;
}

.green {
  color: $success;
}

.blue {
  color: $primary-color
}

.size16 {
  font-size: $size-16;
}

.padding-right-size8 {
  padding-right: $size-8;
}

.label {
  font-size: $size-12;
  color: $gray-dark-1;
}

.circle-icon-wrapper {
  background-color: rgba(57, 155, 255, 0.1);
  border-radius: 50%;
  color: #399BFF;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #399BFF;
    color: #ffffff;
  }
}

.custom-tooltip .ant-tooltip-inner {
  background: rgba(74, 74, 74, 0.9);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  padding: 8px !important;
  max-width: 220px;
}

.ant-tabs-bar, .ant-tabs-top-bar, .ant-tabs-default-bar {
  border: none !important;
}

.ant-table-thead {
  height: 20px;
  font-size: $size-14;
  font-weight: $weight-300;
  color: $gray-dark-2;

  > tr > th {
    font-weight: $weight-600 !important;
  }
}

.ant-table-thead > tr {
  > th:first-child {
    padding-left: 16px;
  }

  > th:last-child {
    padding-right: 16px;
  }
}

.break-all {
  word-break: break-all !important;
}

.break-word {
  word-break: break-word;
}

.ant-table {
  table-layout: fixed;

  .action-column {
    .action-item {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.ant-table, .ant-table-header, .nz-table-hide-scrollbar {
  overflow: hidden;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.centre {
  text-align: center;
}

.ant-table-thead > tr > th {
  vertical-align: middle;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  word-break: break-word;
  padding-right: 20px;
}

.ant-table-thead > tr > th:not(:first-child),
.ant-table-tbody > tr > td:not(:first-child) {
  padding-left: 10px;
}

.ant-table-wrapper.table-in-card {
  .ant-table-content {
    .ant-table-thead {
      height: 40px;
      font-size: $size-14;

      > tr > th {
        color: $gray-dark-1;
        font-weight: $weight-300 !important;
        padding: 12px 12px 12px 4px;

        &:first-child {
          padding-left: 4px;
        }
      }

      tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: rgba(0, 0, 0, 0)
      }
    }

    .ant-table-tbody > tr > td {
      padding: 12px 12px 12px 4px;
      font-weight: $weight-400;
    }
  }

  .ant-table-placeholder {
    color: $gray-light-1;
    .ant-table-expanded-row-fixed {
      padding: 30px;
    }
  }
}

.ant-layout-content {
  padding-left: 8px;
  position: relative;
}

.ant-tabs-tab, .ant-tabs-tab-active {
  font-weight: $weight-600 !important;
}

.hidden {
  display: none;
}

.input-text-overflow {
  border-color: #db5258;

  &:focus {
    border-color: #db5258;
  }
}

.ant-spin, .ant-spin-spinning {
  margin: 24px 0;
}

.ant-table-thead th.ant-table-column-has-sorters {
  cursor: pointer;

  span {
    max-width: fit-content;
    word-break: initial;
  }

  &:hover {
    background: $white !important;
  }

  .ant-table-column-sorter-inner {
    .anticon {
      color: $gray-light-2
    }

    .active {
      color: $primary-color;
    }
  }

}

.ant-table-tbody {
  .loading-more:hover > td {
    background: $white !important;
  }
}

.ant-table-tbody > tr {
  > td {
    font-size: $size-14 !important;

    .account-opportunity {
      font-size: $size-14 !important;
    }
  }
}

.ant-table-content {
  background-color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.ant-table-placeholder {
  border-bottom: none !important;
  margin-top: -5px;
}

.absolute {
  position: absolute;
}

.tooltips {
  border-radius: $size-8 !important;
  padding: $size-8 !important;
  @include tooltip-border;
}

.selector-icon {
  width: 20px;
  height: 20px;
  background-color: rgba(57, 155, 255, 0.1);
  color: $info;
  border-radius: 15px;
  font-size: 9px;
  padding: 5px 3px 2px 3px;
  cursor: pointer;

  &-disabled {
    opacity: 0.5;

    &:hover {
      background-color: rgba(57, 155, 255, 0.1) !important;
      color: $info !important;
      cursor: not-allowed;
    }
  }

  &:hover {
    background-color: $info;
    color: $white;
  }
}

.left {
  margin-right: $size-8
}

.ant-layout-header {
  padding: 0 24px;
  line-height: normal;
}

.ant-radio-group {
  .ant-radio-button-wrapper {
    color: $gray-light-2;
    border-color: #edeef1;
    background-color: rgba(155, 155, 155, 0.1);
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: $info;
    border-color: $info;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  opacity: 1 !important;
}

.deactivated-gray {
  opacity: 0.6;
}

.highlight-range-0 {
  background-color: $white;
}

.highlight-range-1 {
  background-color: #fdf8f8;
}

.highlight-range-2 {
  background-color: #faeff0;
}

.highlight-range-3 {
  background-color: #f7e8e8;
}

.flex-layout {
  display: flex;
}

.timeline-item {
  .ant-timeline-item-head {
    width: $size-8;
    height: $size-8;
    border-color: $gray-light-1;
  }

  &:first-child {
    .ant-timeline-item-head {
      border-color: $info;
    }
  }
}

.ant-timeline-item-tail {
  border-left: 1px dashed $gray-light;
  top: $size-8;
  left: 3.3px;
}

.ant-select {
  font-size: 14px;
  &.ant-select-sm {
    font-size: 12px;
  }
  &.ant-select-open .select-arrow {
    transform: rotateX(180deg);
  }
  &.default-size .ant-select-arrow {
    font-size: 16px;
    right: 5px;
  }
  &.ant-select-disabled:not(.ant-select-customize-input) {
    .ant-select-selector {
      color: $gray-light-2;
      background-color: #F9F9F9;
      border: 1px solid $gray-light-1;
    }
  }
  .anticon {
    color: $gray-light-2;
  }
  &.gray-dark-placeholder {
    .ant-select-selection-placeholder {
      color: $gray-dark-1;
    }
  }
  &.gray-light-placeholder {
    .ant-select-selection-placeholder {
      color: $gray-light-2;
    }
  }
}
.ant-select-dropdown {
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  .ant-select-item {
    padding: 4px 8px;
    min-height: 24px;
    font-size: 12px;
    line-height: 16px;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #FAFAFA;
    color: #1A73BA;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(133, 204, 255, 0.1);
  }
  .anticon.anticon-check {
    color: $info;
  }
}
.default-size-dropdown .ant-select-dropdown {
  .ant-select-item {
    padding: 6px 8px;
    min-height: 32px;
    font-size: 14px;
    line-height: 20px;
  }
}

.ant-select-dropdown-menu {
  max-height: 135px;
}

textarea::-webkit-input-placeholder {
  color: $gray-light-1;
}

.disabled {
  opacity: 0.5;
}

textarea {
  @include input-hover
}

.confirmDeletePopover {
  .ant-popover-inner {
    border-radius: $size-16;
    box-shadow: $box-shadow-2;
  }

  .ant-popover-inner-content {
    padding: 0;
    width: 342px;
  }

  .ant-popover-message {
    padding: $size-8 $size-16 0 $size-16;
    margin-bottom: $size-18;

    .ant-popover-message-title {
      line-height: $size-16;
      font-size: $size-12;
    }

    > .anticon {
      color: #f5a623;
    }
  }

  @include button-style
}

.notesPopover {
  min-height: 0;
  width: 354px;
  border-radius: $size-16;

  .ant-popover-inner {
    border-radius: $size-16;

    .ant-popover-title {
      padding: 0;
      border: none;

    }

    .ant-popover-inner-content {
      padding: 0;

      button {
        min-width: 64px;
        height: 24px;
        margin-left: 16px;
        border-radius: 4px;
        padding: 0;
        font-size: $size-12;
        text-shadow: none;
      }

      button:disabled {
        opacity: 0.5;
      }

      @include button-style
    }
  }

  .ant-popover-arrow {
    background: transparent;
    display: none;
  }
}

.popover-title-container {
  padding: $size-16 $size-24 $size-16 0;
  height: 48px;

  .popover-title {
    height: $size-16;
    border-left: $size-8 solid $primary-color;
    padding-left: $size-16;
    color: $gray-dark-1;
    font-size: $size-16;
    font-weight: $weight-600;
    line-height: $size-16;
    margin-bottom: 20px;
    display: inline-block;
  }
}

.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  right: 7px;
}

.ant-checkbox-checked::after {
  animation: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #399bff;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: #399bff !important;
}

.icon-button {
  width: $size-20;
  height: $size-20;
  cursor: pointer;
}

.icon-button-24 {
  width: $size-24;
  height: $size-24;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #9b9b9b;
}

.ant-tooltip .ant-tooltip-arrow {
  display: none;
}


.ant-tooltip-inner {
  padding: 0;
  font-size: $size-12;
  font-weight: $weight-300;
  box-shadow: none;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.error-input {
  border-color: $error !important;

  input {
    border-color: $error !important;
  }
}

// button
.ant-btn {
  border-color: #1A73BA;
  color: #1A73BA;
  height: 24px;
  font-size: 12px;
  line-height: 22px;
  padding: 0 12px;
  font-weight: 600;
  border-radius: 4px;
  &.ant-btn-link {
    color: #1a73ba;
    border-color: transparent;
    background: transparent;
    box-shadow: none;
    font-weight: 400;
    &:hover {
      border-color: transparent;
    }
  }
  &.ant-btn-sm {
    height: 16px;
    line-height: 14px;
  }
  &.ant-btn-lg {
    height: 32px;
    line-height: 30px;
  }
  &:hover {
    border-color: #399BFF;
    color: #399BFF;
  }
  &[disabled] {
    border-color: #1A73BA;
    color: #1A73BA;
    opacity: .5;
  }
  &.ant-btn-primary {
    background-color: #1A73BA;
    color: #ffffff;
    &:hover {
      background-color: #399BFF;
    }
    &[disabled] {
      color: #ffffff;
      background-color: #1A73BA;
      border-color: #1A73BA;
    }
  }
  &[nztype='default'] {
    .anticon-loading {
      color: $primary-color;
    }
  }
}

.ant-alert-success .ant-alert-icon .anticon {
  color: #96d368;
}

.ant-alert-warning .ant-alert-icon .anticon {
  color: #F5A623;
}

.ant-alert-error .ant-alert-icon .anticon {
  color: #db5157;
}
.ant-message-notice-content {
  padding: 8px 16px;
  line-height: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.85);
  .ant-message-loading .anticon {
    color: #1A73BA;
  }
}

.ant-modal {
  .ant-modal-close .anticon {
    color: #6F6F6F;
  }
  .modal-title-with-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4A4A4A;
    position: absolute;
    &::before {
      content: '';
      position: absolute;
      left: -24px;
      top: 2px;
      width: 8px;
      height: 16px;
      background-color: #1A73BA;
    }
  }

  &.modal-common-style {
    .ant-modal-content {
      border-radius: 16px;
      height: 80vh;
    }
    .ant-modal-header {
      border-bottom: none;
      border-radius: 16px 16px 0 0;
      height: 48px;
    }
    .ant-modal-body {
      height: calc(80vh - 120px);
    }
    .ant-modal-footer {
      padding: 0 16px;
    }
    .operation-btns {
      padding: 24px 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      button {
        margin-left: 16px;
        min-width: 64px;
        min-height: 24px;
      }
    }
  }
}

.ant-picker-today-btn:hover {
  color: #399BFF;
}
.ant-input {
  line-height: 22px;
}

.ant-input[disabled] {
  color: $gray-light-2;
  background-color: #F9F9F9;
  border: 1px solid $gray-light-1;
}

textarea {
  &.ant-input[disabled]:hover {
    border-color: $gray-light-1 !important;
  }
}

.ant-input-number .ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number-group-addon {
  background-color: $white;
}

.ant-input-number-disabled {
  color: $gray-light-2;
  background-color: #F9F9F9;
  border: 1px solid $gray-light-1;
}

.amount-input-prefix {
  padding: 5px 8px 4px 8px;
  background: $white;
  border: 1px solid #d9d9d9;
}

.amount-input-prefix-disabled {
  padding: 5px 8px 4px 8px;
  color: $gray-light-2;
  background-color: #F9F9F9;
  border: 1px solid $gray-light-1;
}

.ant-dropdown .normal-color-link {
  color: $gray-dark-1;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.error-tip {
  background: rgba($error, 0.04);
  border: 1px solid $error;
  border-radius: 4px;
  font-size: 12px;
  color: $gray-dark-1;
  padding: 8px;
  text-align: left;
  position: absolute;
  max-width: 600px;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
}

.status-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 4px;
  border-radius: 7px;
  font-size: 10px;
  font-weight: 600;
  margin: 2px 8px;
  white-space: nowrap;
  background-color: rgba($gray-light-1, 0.5);
  color: $gray-light-2;

  &.orange-warning {
    color: $orange;
    background: rgba($orange, 0.2);;
  }

  &.green-done {
    color: $success;
    background: rgba($success, 0.2);;
  }
}

app-icon-text {
  display: inline-flex;
  align-items: center;
}


